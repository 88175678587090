import React, { Component } from 'react';
import { LINKS } from "../constants/links";

export default class extends Component {
	componentDidMount() {
		window.location.href = LINKS.VIMEO_VIDEO;
	}

	render() {
		return null
	}
}